.app {
  background: rgb(136, 136, 136);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.367), rgba(0, 0, 0, 0));
  min-height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: MenoBold, serif;
  letter-spacing: 0.04em;
  -webkit-font-smoothing: antialiased;
}

.background {
  background-image: url(./assets/images/background-final-small.jpg);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  margin: 0;
  font-size: 6.5vmin;
  display: flex;
  align-items: center;
  justify-content: center;
}

h2 {
  margin: 0.2em;
  font-size: 4vmax;
}

.location {
  margin: -0.3em 0 0.1em 0;
  font-size: calc(20px + 1.2vw);
  font-weight: 100;
  font-family: Parisienne, serif;
}

.ampersand {
  font-size: 2.0em;
  margin: 0 0.2em;
}

.navigation-layout {
  display: flex;
  flex-direction: row;
  margin-top: 16pt;
  margin-bottom: 13.5em;
}

@font-face {
  font-family: 'Parisienne';
  src: local('Parisienne'), url(./assets/fonts/Parisienne.ttf) format('truetype');
}

@font-face {
  font-family: 'MenoBold';
  src: local('Meno-Bold'), url(./assets/fonts/Meno-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Libre';
  src: local('LibreBaskerville-Bold'), url(./assets/fonts/LibreBaskerville-Bold.ttf) format('truetype');
}