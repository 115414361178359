.link-layout {
  margin: 0 2.1vmin;
}

.nav-button {
  appearance: none;
  border: 0px;
  border-radius: 2pt;
  cursor: pointer;
  display: inline-block;
  color: #444;
  font-size: 1rem;
  line-height: normal;
  min-height: 1.5rem;
  min-width: 130px;
  outline: none;
  text-align: center;
  touch-action: manipulation;
  width: 100%;
  font-family: Libre, serif;
  letter-spacing: 0.05em;
  padding: 1em;
  text-transform: uppercase;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  background-color: rgb(255, 255, 255);
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.631);
}